var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "19",
        viewBox: "0 0 21 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M21.0001 7.18735V7.11878V7.04397C21.0001 7.00657 21.0001 7.00657 20.9636 6.9754C20.9636 6.938 20.9271 6.938 20.9271 6.90684C20.9271 6.86943 20.8906 6.86943 20.8906 6.83827C20.8906 6.80086 20.8541 6.80086 20.8541 6.7697L20.8175 6.7323L17.0691 2.54332C17.0691 2.50592 17.0326 2.50592 17.0021 2.50592L16.9656 2.46851C16.9291 2.46851 16.9291 2.43111 16.8987 2.43111C16.8622 2.43111 16.8622 2.39371 16.8317 2.39371C16.7952 2.39371 16.7952 2.39371 16.7648 2.35631C16.7283 2.35631 16.7283 2.35631 16.6979 2.31891H16.6309H16.564H12.134C11.0082 2.31891 10.0894 3.26018 10.0894 4.4134V14.1877C10.0894 15.3409 11.0082 16.2822 12.134 16.2822H18.9494C20.0752 16.2822 20.994 15.3409 20.994 14.1877V7.20605L21.0001 7.18735ZM16.9109 4.42586L18.7851 6.48295H17.5924C17.2151 6.48295 16.9109 6.17127 16.9109 5.78479V4.42586ZM18.9555 14.8983H12.1401C11.7628 14.8983 11.4585 14.5866 11.4585 14.2001V4.42586C11.4585 4.03938 11.7628 3.7277 12.1401 3.7277H15.5478V5.82219C15.5478 6.97541 16.4666 7.91668 17.5924 7.91668H19.637V14.2001C19.637 14.5866 19.3328 14.8983 18.9555 14.8983Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.08114 10.1358H6.73022V15.5528C6.73022 15.6089 6.70588 15.665 6.66937 15.7024C6.63286 15.7398 6.57809 15.7648 6.52333 15.7648H5.08114V15.0666C5.08114 14.73 4.95335 14.4058 4.71602 14.169C4.4787 13.9259 4.16836 13.7949 3.83976 13.7949H1.24138C0.906694 13.7949 0.596349 13.9259 0.365112 14.169C0.127789 14.4121 0 14.73 0 15.0666V17.7283C0 18.0712 0.127789 18.3891 0.365112 18.626C0.602434 18.8691 0.912779 19 1.24138 19H3.83976C4.16836 19 4.48479 18.8691 4.71602 18.626C4.95335 18.3829 5.08114 18.065 5.08114 17.7283V17.0302H6.52333C6.90669 17.0302 7.2718 16.8743 7.54564 16.6001C7.81947 16.3196 7.9716 15.9518 7.9716 15.5528V10.1358H10.1927V8.87041H7.9716V3.44718C7.9716 3.05446 7.81947 2.68045 7.55172 2.39993C7.27789 2.11942 6.91886 1.96358 6.52941 1.96358H5.08114V1.27165C5.08114 0.935039 4.95335 0.610892 4.71602 0.374016C4.4787 0.130906 4.16836 0 3.83976 0H1.24138C0.912779 0 0.596349 0.130906 0.365112 0.374016C0.127789 0.617126 0 0.935039 0 1.27165V3.9334C0 4.27625 0.127789 4.59416 0.365112 4.83104C0.602434 5.07415 0.912779 5.20505 1.24138 5.20505H3.83976C4.16836 5.20505 4.48479 5.07415 4.71602 4.83104C4.95335 4.58793 5.08114 4.27001 5.08114 3.9334V3.23524H6.52333C6.57809 3.23524 6.63286 3.26017 6.66937 3.29757C6.70588 3.33497 6.73022 3.39108 6.73022 3.44718V8.86417H5.08114M1.23529 3.9334V1.27165C1.23529 1.26542 1.23529 1.26542 1.24138 1.26542H3.83976C3.84584 1.26542 3.84584 1.26542 3.84584 1.27165V3.9334C3.84584 3.93963 3.84584 3.93963 3.83976 3.93963H1.24138C1.24138 3.93963 1.23529 3.93963 1.23529 3.9334ZM3.83976 17.7346H1.24138C1.23529 17.7346 1.23529 17.7346 1.23529 17.7283V15.0666C1.23529 15.0604 1.23529 15.0604 1.24138 15.0604H3.83976C3.84584 15.0604 3.84584 15.0604 3.84584 15.0666V17.7283C3.84584 17.7283 3.84584 17.7346 3.83976 17.7346Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.08114 8.17223C5.08114 7.83561 4.95335 7.51147 4.71602 7.27459C4.4787 7.03148 4.16836 6.90057 3.83976 6.90057H1.24138C0.906694 6.90057 0.596349 7.03148 0.365112 7.27459C0.127789 7.5177 0 7.83561 0 8.17223V10.834C0 11.1768 0.127789 11.4947 0.365112 11.7316C0.602434 11.9747 0.912779 12.1056 1.24138 12.1056H3.83976C4.16836 12.1056 4.48479 11.9747 4.71602 11.7316C4.95335 11.4885 5.08114 11.1706 5.08114 10.834V10.1358V8.87039V8.17223Z",
          fill: "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }